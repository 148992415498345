import logo from "./images/friendshipsocks_logo.png"
import background from "./images/barcelona.jpg"
import "./App.css"

function App() {
  return (
    <div className="App">
      <header className="header">
        <div className="header-flex">
          <div className="header-left">
            <img src={logo} className="logo" alt="logo" />
          </div>
          <div className="header-center"></div>
          <section className="header-right mdc-top-app-bar__section mdc-top-app-bar__section--align-end" role="toolbar">
            <button className="material-icons mdc-top-app-bar__action-item mdc-icon-button" aria-label="Home">
              <span className="mdc-button__label"> home </span> 
            </button>
            <button className="material-icons mdc-top-app-bar__action-item mdc-icon-button" aria-label="Login">
              login
            </button>
            <button className="material-icons mdc-top-app-bar__action-item mdc-icon-button" aria-label="Basket">
              shop
            </button>
            <button className="material-icons mdc-top-app-bar__action-item mdc-icon-button" aria-label="Search">
              search
            </button>
          </section>
        </div>
        <div className="header-banner-flex">
          <img src={background} className="background" alt="background" />
        </div>
        <div className="header-flex">
          <div className="header-right">
            <button className="mdc-button"> 
              <div className="mdc-button__ripple"></div>
              <span className="mdc-button__label"> Food and Beverage </span> 
            </button>
            <button className="mdc-button"> Fashion Boutique </button>
            <button className="mdc-button"> Gift Shop </button>
            <button className="mdc-button">
              <span className="mdc-button__label"> Handcrafted Furniture and Pottery  </span> 
            </button>
            <button className="mdc-button"> Plant Nursery </button>
            <button className="mdc-button"> Art Gallery </button>
            <button className="mdc-button"> Travel and Tours </button>
            <button className="mdc-button"> Language School </button>
          </div>
        </div>
      </header>
      <body> </body>
    </div>
  )
}

export default App
